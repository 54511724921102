import React, {useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {changeArticleField} from "../features/article/articleSlice";
import {useDispatch} from "react-redux";
import Paper from "@mui/material/Paper";

export default function ArticleStatus(props) {

    const status = props.status
    const [initialStatus] = useState(status);
    const dispatch = useDispatch()

    const handleChange = (event) => {
        dispatch(changeArticleField({status: event.target.value}))
    }

    return (
        <Paper>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Article Status</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    label="Article Status"
                    disabled={(initialStatus === "published")}
                    onChange={handleChange}
                >
                    <MenuItem value="draft">Draft</MenuItem>
                    <MenuItem value="scheduled">Scheduled</MenuItem>
                    <MenuItem value="published">Published</MenuItem>
                </Select>
            </FormControl>
        </Paper>
    )
}