// Import the RTK Query methods from the React-specific entry point
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

// Define our single API slice object
export const apiSlice = createApi({
    // The cache reducer expects to be added at `state.api` (already default - this is optional)
    reducerPath: 'api',
    // All of our requests will have URLs starting with '/fakeApi'
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        mode: "cors",
        prepareHeaders: (headers, { getState }) => {
            let accessToken = getState().auth.accessToken;
            if (accessToken) {
                headers.set('Authorization', `Bearer ${accessToken}`)
            }

            return headers
        },
    }),
    tagTypes: ['Media', 'Article', 'MediaTags'],
    // The "endpoints" represent operations and requests for this server
    endpoints: builder => ({
        // The `getPosts` endpoint is a "query" operation that returns data
        getArticles: builder.query({
            // The URL for the request is '/fakeApi/posts'
            query: (params) => `article?town=${params.town}&limit=${params.limit}&page=${params.page}&category=${params.category}`,
            providesTags: (result) =>
                result ?
                    [
                        ...result.results.map(({id}) => ({type: 'Article', id})),
                        {type: 'Article', id: 'LIST'},
                    ]
                    :
                    [{type: 'Article', id: 'LIST'}],
        }),
        getArticle: builder.query({
            async queryFn(articleId, _queryApi, _extraOptions, fetchWithBQ) {
                if(articleId !== 'new') {
                    return fetchWithBQ(`article/${articleId}`);
                }
                return {data: []}
            },
            providesTags: (result, error, id) => [{type: 'Article', id}],
        }),
        getMedia: builder.query({
            query: (params) => {
                let queryString = `media?limit=${params.limit}&page=${params.page}&search=${params.search}`
                let tagQuery = params.tags.map(s => "tag[]=" + s).join("&");
                return [queryString, tagQuery].join("&");
            },
            providesTags: (result) =>
                result ?
                    [
                        ...result.results.map(({id}) => ({type: 'Media', id})),
                        {type: 'Media', id: 'LIST'},
                    ]
                    :
                    [{type: 'Media', id: 'LIST'}],
        }),
        getMediaSingle: builder.query({
            query: (id) => `media/${id}`,
            providesTags: (result, error, id) => [{type: 'Media', id}],
        }),
        getMediaUploadUrl: builder.query({
            query: () => 'media/upload',
            keepUnusedDataFor: 0
        }),
        getMediaTags: builder.query({
            query: (params) => {
                let queryString = "media/tags"
                let tagQuery = params.tags.map(s => "tag[]=" + s).join("&");
                if (tagQuery) {
                    return [queryString, tagQuery].join("?");
                }
                return queryString;
            },
            providesTags: ['MediaTags']
        }),
        updateMedia: builder.mutation({
            query: (body) => ({
                url: `media/${body.id}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Media', id: arg.id}, 'MediaTags'],
        }),
        describeMedia: builder.mutation({
            query: (body) => ({
                url: `media/${body.id}/describe`,
                method: 'GET'
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Media', id: arg.id}, 'MediaTags'],
        }),
        createMedia: builder.mutation({
            query: (body) => ({
                url: `media`,
                method: 'POST',
                body
            }),
            invalidatesTags: [{type: 'Media', id: 'LIST'}]
        }),
        getRegions: builder.query({
            query: (search) => {
                return 'region?search=' + search
            }
        }),
        getRegionsTowns: builder.query({
            query: (id) => {
                return `region/${id}/towns`
            }
        }),
        saveArticle: builder.mutation({
            query: (body) => ({
                url: `article/${body.id}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Article', id: arg.id}],
        }),
        createArticle: builder.mutation({
            query: (body) => ({
                url: `article`,
                method: 'POST',
                body
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Article'}],
        }),
        aiInferHeadline: builder.mutation({
            query: (body) => ({
                url: `ai/headline`,
                method: 'POST',
                body
            }),
        }),
    })
})

export const {
    useGetArticlesQuery,
    useGetArticleQuery,
    useGetMediaQuery,
    useGetMediaTagsQuery,
    useGetMediaSingleQuery,
    useGetMediaUploadUrlQuery,
    useGetRegionsQuery,
    useGetRegionsTownsQuery,
    //Mutations
    useUpdateMediaMutation,
    useDescribeMediaMutation,
    useCreateMediaMutation,
    useSaveArticleMutation,
    useAiInferHeadlineMutation
} = apiSlice