import * as React from 'react';

import {useGetArticlesQuery} from "../features/api/apiSlice";
import {
    ListItem, Skeleton, Table,
    TableBody,
    TableCell, TableContainer, TableHead,
    TablePagination,
    TableRow,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {useState} from "react";
import RenderStatusChip from "../renderers/RenderStatusChip";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import List from "@mui/material/List";
import {Edit} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {useDispatch} from "react-redux";
import {clear} from "../features/article/articleSlice";


export default function ArticleTable(props) {
    let navigate = useNavigate();
    const town = props.town;
    const category = props.category;
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const dispatch = useDispatch();
    const {data, isLoading, isSuccess, isFetching} = useGetArticlesQuery({
        town: town,
        limit: pageSize,
        page: page,
        category: category
    })


    function handleChangePage(event, newPage) {
        setPage(newPage)

    }

    function handleChangeRowsPerPage(event) {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    }

    const renderBox = () => {
        return (
            <Box mb={10} component={Paper}>
                <List>
                    {data.results.map(result => (<ListItem key={result.id}
                                                           secondaryAction={
                                                               <IconButton edge="end" aria-label="edit"
                                                                           onClick={(e) => {
                                                                               e.stopPropagation(); // don't select this row after clicking
                                                                               dispatch(clear());
                                                                               navigate('/articles/' + result.id);
                                                                           }}>
                                                                   <Edit/>
                                                               </IconButton>
                                                           }
                    >
                        <ListItemText
                            primary={result.headline}
                            secondary={result.status}
                        />
                    </ListItem>))}
                </List>
                <TablePagination
                    component="div"
                    count={(data ? data.count : 0)}
                    page={(data ? data.currentPage : 0)}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        )
    }

    if (isFetching) {
        return (<Skeleton variant='rectangular' width={'100%'} height={300}>
        </Skeleton>)
    }

    if (!isDesktop && isSuccess && !isLoading) {
        return renderBox();
    }

    if (isSuccess && !isLoading) {
        return (
            <Box mb={10} component={Paper} sx={{width:'100%'}}>
                <TableContainer component={Box} sx={{maxHeight: 'calc(100vh - 300px)'}}>
                    <Table stickyHeader sx={{minWidth: 700}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Headline</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="left">Updated Date</TableCell>
                                <TableCell align="left">Published Date</TableCell>
                                <TableCell align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.results.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.headline}
                                    </TableCell>
                                    <TableCell align="left"><RenderStatusChip value={row.status}/></TableCell>
                                    <TableCell align="left">{moment(row.updated_on).format('DD-MM-YYYY HH:mm')}</TableCell>
                                    <TableCell align="left">{moment(row.published_on).format('DD-MM-YYYY HH:mm')}</TableCell>
                                    <TableCell align="center"> <IconButton edge="end" aria-label="edit"
                                                                           onClick={(e) => {
                                                                               e.stopPropagation(); // don't select this row after clicking
                                                                               navigate('/articles/' + row.id);
                                                                           }}>
                                        <Edit/>
                                    </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={(data ? data.count : 0)}
                    page={(data ? data.currentPage : 0)}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        );

    }
}