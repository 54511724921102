import React,{ useRef, useState, useEffect } from 'react';
import {Stack} from "@mui/material";
import { Editor } from '@tinymce/tinymce-react';
import {useDispatch, useSelector} from "react-redux";
import {changeBlockValidity, changeHtmlBlock, setDirty} from "../../features/article/articleSlice";
import {useDndMonitor} from '@dnd-kit/core';
import Box from "@mui/material/Box";

export default function HtmlBlock(props) {
    const editorRef = useRef(null);
    const [hideEditor, setHideEditor] = useState(false);
    const dispatch = useDispatch();


    useDndMonitor({
        onDragStart(event) {
            setHideEditor(true);
        },
        onDragEnd(event) {
            setHideEditor(false);
        },
    });

    useEffect(() => {
        dispatch(changeBlockValidity({
            valid: (props.block.html !== ''),
            blockId: props.block.id,
            pageId: props.pageId
        }));
    }, [props.block.html])

   let content = (<Editor
        key={props.block.id}
        apiKey='bc7km23mmzios7kwyk9jk7xmbomsmcq8dzif4w4rnccnt27w'
        onInit={(evt, editor) => editorRef.current = editor}
        onEditorChange={(e) => dispatch(changeHtmlBlock({event:e, pageId: props.pageId, blockId: props.block.id}))}
        onFocusOut={() => dispatch(setDirty(true))}
        value={props.block.html}
        init={{
            menubar: false,
            min_height: 100,
            mobile: {
                menubar: false,
                plugins: [ 'lists', 'autolink' ],
                toolbar: [ 'undo', 'bold', 'italic', 'bullist' ],
            },
            contextmenu:"paste cut copy | link",
            plugins: [
                'advlist', 'autolink', 'autoresize', 'lists', 'link',
                'anchor', 'searchreplace', 'fullscreen', 'wordcount', 'contextmenu', 'paste'
            ],
            toolbar: 'undo redo | blocks | ' +
                'bold italic | link | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            paste_as_text:true
        }}
    />)
    if(hideEditor)  {
        content = (<Box sx={{
            height:'100px',
            display:'flex',
            alignItems: 'center',
            justifyContent:'center'

        }}>Editor disabled whilst dragging</Box>)
    }
    return content;
}