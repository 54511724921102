import React, {useEffect, useState} from "react";
import ArticleTable from "../components/ArticleTable";
import {Backdrop, SpeedDial, SpeedDialAction, SpeedDialIcon, Stack, Tab, useMediaQuery, useTheme} from "@mui/material";
import {Article} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import TownSelector from "../components/ArticleTownDetails/TownSelector";
import {useLocalStorage} from "../app/hooks";
import {clear} from "../features/article/articleSlice";
import {useDispatch} from "react-redux";
import {TabContext, TabList} from "@mui/lab";

export default function ArticleList() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [town, setTown] = useLocalStorage("initialTown", '')
    const [category, setCategory] = useLocalStorage("category", 'news')
    const [articleCategory, setArticleCategory] = useState(category)
    const [articleType, setArticleType] = useState('standard')

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch();
    const handleCreate = (type, choseCategory) => {
        dispatch(clear());
        navigate('/articles/new/' + type + '/' + choseCategory)
    }
    const handleFabCreate = (type, choseCategory) => {
        if (isDesktop) {
            handleCreate(type, choseCategory);
        }
    }

    const handleTownSelect = (value) => {
        setTown(value.slug)
    }

    const handleCategoryChange = (event, newValue) => {
        setCategory(newValue);
    };

    useEffect(() => {
        console.log(category);
        if (category) {
            if (category === 'events') {
                setArticleType('event')
            } else {
                setArticleType('standard')
            }
            setArticleCategory(category)
        }
    }, [category]);

    return (
        <Box sx={{width:'100%'}}>
            <Stack spacing={2}>
                <Box>
                    <TownSelector direction="row" onSelect={handleTownSelect}></TownSelector>
                </Box>
                <Box>
                    <TabContext value={category}>
                        <TabList onChange={handleCategoryChange} aria-label="Category tabs">
                            <Tab label="News" value='news'/>
                            <Tab label="Events" value='events'/>
                            <Tab label="Lifestyle" value='lifestyle'/>
                        </TabList>
                    </TabContext>
                </Box>
                <ArticleTable town={town} category={category}></ArticleTable>
            </Stack>
            <Backdrop open={open} sx={{zIndex: theme.zIndex.drawer + 2}}/>
            <SpeedDial
                ariaLabel="Create"
                sx={{position: 'absolute', bottom: 16, right: 16, zIndex: theme.zIndex.drawer + 3}}
                icon={<SpeedDialIcon/>}
                onClose={handleClose}
                onOpen={handleOpen}
                onClick={() => handleFabCreate(articleType, articleCategory)}
                open={open}
            >
                <SpeedDialAction
                    key="article"
                    icon={<Article/>}
                    tooltipTitle="Article"
                    tooltipOpen
                    onClick={() => handleCreate(articleType, articleCategory)}
                />
            </SpeedDial>
        </Box>
    )
}