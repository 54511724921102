import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems } from '../components/Navigation';
import {Outlet, useLocation} from "react-router-dom";
import {CircularProgress, Snackbar, useMediaQuery, useTheme} from "@mui/material";
import {useLocalStorage} from "../app/hooks";
import {useEffect, useState} from "react";
import {setAccessToken, setIdTokenClaims} from "../features/auth/authSlice";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch, useSelector} from "react-redux";
import {Alert} from "@mui/lab";

const titles = {
    '/': 'Dashboard',
    '/articles': 'Articles',
}

const drawerWidth = 200;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: 0,
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(7),
                },
            }),
        },
    }),
);

const mdTheme = createTheme();

function DashboardContent() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const [open, setOpen] = useLocalStorage("sidebar", isDesktop);
    const [title, setTitle] = useState("");
    const location = useLocation();
    const toggleDrawer = () => {
        setOpen(!open);
    };
    let drawtype = 'permanent'

    useEffect(() => {
        if(isDesktop) {
            drawtype = 'permanent'
        } else {
            drawtype = "temporary"
        }
    }, [isDesktop])

    useEffect(
        () => {
            let documentTitle = (titles[location.pathname] ? titles[location.pathname] : 'Edit') + ' - EyeOn Admin'
            document.title = documentTitle
            setTitle(documentTitle.replace(' - EyeOn Admin',''));
        },
        [location],
    )

    const {isAuthenticated, getAccessTokenWithPopup,error, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const dispatch = useDispatch();
    // @ts-ignore
    const auth = useSelector((state) => state.auth)


    useEffect(()=> {
        const getAccessToken = async () => {
            if(isAuthenticated && !auth.isAuthenticated) {
                const accessToken = await getAccessTokenSilently({
                    audience: `https://api.eyeon.uk`,
                    scope: "openid profile email",
                });
                const idTokenClaims = await getIdTokenClaims();
                dispatch(setAccessToken(accessToken))
                dispatch(setIdTokenClaims(idTokenClaims))
            }
        }
        getAccessToken();
    }, [auth, getAccessTokenWithPopup, isAuthenticated])

    if (!auth.isAuthenticated) {
        return (
            <div className="page-layout">
                <CircularProgress />
                <Snackbar open={error} autoHideDuration={6000}>
                    <Alert severity="error">
                        {JSON.stringify(error)}
                    </Alert>
                </Snackbar>
            </div>
        );
    }

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            {title}
                        </Typography>
                        <IconButton color="inherit">
                            <Badge badgeContent={4} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer variant={drawtype} open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        {mainListItems(location)}
                        {/*<Divider sx={{ my: 1 }} />*/}
                        {/*{secondaryListItems}*/}
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="xl" sx={{ mt: 3, mb: 4, ml:0 }}>
                        <Outlet />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default function Dashboard() {
    return <DashboardContent />;
}