import * as React from 'react';
import Chip from '@mui/material/Chip';

export default function RenderStatusChip(params) {
    const status = params.value ?? '';
    let chipColor = "default";

    if(status === "scheduled") {
        chipColor = "warning"
    }

    if(status === "published") {
        chipColor = "success"
    }

    return (
       <Chip
           label={status}
           color={chipColor}
       />
    );
}