import {ImageListItem, ImageListItemBar, useTheme} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Edit} from "@mui/icons-material";
import EditDescription from "./EditDescription";
import React, {useState} from "react";
import {useGetMediaSingleQuery} from "../../../../features/api/apiSlice";
import {imageDeliveryUrl} from "../../../../app/helpers";


export default function EOSImageListItem(props) {
    const media = props.media;
    const theme = useTheme();
    const [descriptionOpen, setDescriptionOpen] = useState(false);


    const handleEditDescriptionClose = () => {
        setDescriptionOpen(false);
    }



    return (
        <ImageListItem key={media.id}  sx={{border:'4px solid',overflow:'hidden', borderColor:(props.imageIsSelected(media) ? theme.palette.primary.main: 'transparent')}}>
            <img
                src={`${imageDeliveryUrl(media.renditions.thumbnail.href)}?w=248&fit=crop&auto=format`}
                alt={(media.description ? media.description.caption : '')}
                loading="lazy"
                referrerPolicy="no-referrer"
                onClick={() => props.selectImage(media)}
                style={{cursor:'pointer'}}
            />
            <ImageListItemBar
                title={(media.description ? media.description.caption : '')}
                subtitle={(media.description ? media.description.tags.join(', ') : '')}
                actionIcon={<IconButton
                    sx={{color: 'rgba(255, 255, 255, 0.54)'}}
                    aria-label="edit image details"
                    onClick={() => setDescriptionOpen(true)}
                >
                    <Edit/>
                </IconButton>}
            />
            <EditDescription media={media} open={descriptionOpen} onClose={handleEditDescriptionClose}></EditDescription>
        </ImageListItem>
    )
}