import React, {useEffect, useState} from "react";
import {useAiInferHeadlineMutation, useGetArticleQuery} from "../../features/api/apiSlice";
import {Button, CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import {changeArticleField} from "../../features/article/articleSlice";

export default function AIHeadlinePicker(props) {
    let [infer, inferData] = useAiInferHeadlineMutation()
    const dispatch = useDispatch();
    const headlinePicked = (headline) => {
        dispatch(changeArticleField({'headline': headline}));
        props.onHeadlinePicked(headline);
    }
    useEffect(() => {
        console.log('log')
        if(props.open && (inferData.isUninitialized || inferData.isSuccess)) {
            infer(props.article)
        }
    }, []);

    if(inferData.isSuccess) {
        console.log(inferData)
        return (
            <div className="ai-headline-picker">
                <h2>Headlines</h2>
                <ul>
                    {inferData.data.map((headline, index) => {
                        return (
                            <li key={index} onClick={() => headlinePicked(headline)}>{headline}</li>
                        );
                    })}
                </ul>
                <Button onClick={() => infer(props.article)}>Generate more</Button>
            </div>
        );
    } else {
        return (
            <div className="ai-headline-picker-loading">
                <Typography className='article-loader__heading'>Generating Headlines</Typography>
                <CircularProgress className='article-loader__progress' />
            </div>
        );
    }


}