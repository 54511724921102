import React, {useEffect, useState} from 'react';
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors,} from '@dnd-kit/core';
import {SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy,} from '@dnd-kit/sortable';
import {deletePage, reorderBlocks, setIsValid, setPageUpdateDate} from "../features/article/articleSlice";

import Block from './Block';
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import {Button, Stack, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import AddNewBlock from "./AddNewBlock";
import {MeasuringStrategy} from '@dnd-kit/core';
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import UpdatePageDateDialog from "./UpdatePageDateDialog";
import article from "../types/Article";

const measuringConfig = {
    droppable: {
        strategy: MeasuringStrategy.Always,
    }
};

function Pages() {
    const dispatch = useDispatch();
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const pages = useSelector((state) => state.article.page)
    const [newOpen, setNewOpen] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedPageId, setSelectedPageId] = useState(null);

    const handleOpenPageDialog = (pageId) => {
        setSelectedPageId(pageId);
        setDialogOpen(true);
    };

    const handleClosePageDialog = () => {
        setDialogOpen(false);
    };

    const handlePageDateChange = (date) => {
        // Logic to update the date of the selected page
        dispatch(setPageUpdateDate({pageId: selectedPageId, update_date: date}));
    };

    const handleDeletePage = (selectedPage) => {
        dispatch(deletePage(selectedPage))
        handleClosePageDialog();
    };


    useEffect(() => {
        let isValid = true;
        pages.forEach((page) => {
            if (page.blocks.length === 0) {
                isValid = false;
            }
            page.blocks.forEach((block) => {
                if (!block.valid) {
                    isValid = false;
                }
                if (block.valid === undefined) {
                    isValid = true
                }
            })
        })
        dispatch(setIsValid({blocks: isValid}));
    }, [pages])

    let sortedPages = [...pages].sort((a, b) => {
        if (!a.update_date) return 1; // a should be sorted to the bottom
        if (!b.update_date) return -1; // b should be sorted to the bottom
        return moment(b.update_date).valueOf() - moment(a.update_date).valueOf(); // sort in descending order by date
    });
    return (<div>
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={(event) => handleDragEnd(event, pages, dispatch)}
            measuring={measuringConfig}
        >

            {sortedPages.map((page, pageIndex) => {
                let blocks = page.blocks;
                return (
                    <Stack
                        direction="column"
                        alignItems="center"
                        spacing={0}
                        sx={{
                            width: "100%",
                            border: page.update_date ? "1px solid #e0e0e0" : "none",
                            margin: page.update_date ? "0 0 20px 0" : "0",
                            padding: page.update_date ? "0 20px" : "0",
                            background: page.update_date ? "rgba(100,100,100,0.1)" : "none",
                        }}
                    >
                        {page.update_date && <Stack
                            direction="row"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            justifyItems="space-between"
                            sx={{padding: "10px 0", width: "100%"}}
                        >
                            <Typography variant="caption" fontWeight="bold" textTransform="uppercase" sx={{flexGrow: 1}}>Update</Typography>
                            <Tooltip title="Edit Date" placement="right">
                                <Typography onClick={() => handleOpenPageDialog(page.id)} variant="caption" fontWeight="bold" textTransform="uppercase" sx={{flexGrow: 1, cursor: "pointer"}} align={"right"}>{moment(page.update_date).format('DD-MM-YYYY HH:mm')}</Typography>
                            </Tooltip>
                        </Stack>}
                        <Stack spacing={0}  sx={{
                                width: "100%" }}>
                            <SortableContext
                                items={blocks.length > 0 ? blocks : ['empty']}
                                page={page.id}
                                strategy={verticalListSortingStrategy}
                            >
                                {blocks.map((block, index) => {
                                    return (
                                        <Stack key={block.id} spacing={0}>
                                            <Block id={block.id} pageId={page.id} pageIndex={pageIndex}
                                                   block={block} blockIndex={index}/>
                                            <AddNewBlock pageId={page.id} blockIndex={index}/>
                                        </Stack>
                                    )
                                })}
                                {blocks.length === 0 &&
                                    <Stack spacing={0}>
                                        <Typography>Empty</Typography>
                                        <AddNewBlock pageId={page.id} blockIndex={0}/>
                                    </Stack>
                                }
                            </SortableContext>
                        </Stack>
                    </Stack>
                )
            })}
        </DndContext>
        <UpdatePageDateDialog
            open={dialogOpen}
            handleClose={handleClosePageDialog}
            handleDateChange={handlePageDateChange}
            handleDelete={handleDeletePage}
            selectedPage={selectedPageId}
            pages={pages}
        />
    </div>)
}

function handleDragEnd(event, pages, dispatch) {
    const {active, over} = event;
    const sourcePageId = pages.find(page => page.blocks.some(block => block.id === active.id)).id;
    const targetPageId = pages.find(page => page.blocks.some(block => block.id === over.id)).id;

    dispatch(reorderBlocks({
        active: active.id,
        over: over.id,
        sourcePageId: sourcePageId,
        targetPageId: targetPageId
    }))
}

export default Pages;