import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import React from "react";
import {CircularProgress, Snackbar} from "@mui/material";
import {Alert} from "@mui/lab";

export const ProtectedRoute = ({ component }) => {
    const {error} = useAuth0()
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <div className="page-layout">
                REDIRECTING
                <CircularProgress />
                <Snackbar open={error} autoHideDuration={6000}>
                    <Alert severity="error">
                        {JSON.stringify(error)}
                    </Alert>
                </Snackbar>
            </div>
        ),
    });

    return <Component />;
};