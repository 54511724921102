import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';


export const usePrompt = (when = false) => {
    const [flag, setFlag] = useState(false);
    const confirm = useRef(null);
    const context = useRef(null);
    const { navigator } = useContext(NavigationContext);
    const blockNavigator = navigator;

    const next = useCallback(() => {
        confirm.current();
        context.current?.retry?.();
    }, [flag]);

    useEffect(() => {
        if (!when) return;
        const unblock = blockNavigator.block((tx) => {
            setFlag(true);
            context.current = tx;
        });
        confirm.current = unblock;
        return unblock;
    }, [blockNavigator, when]);

    return [flag, setFlag, next];
};