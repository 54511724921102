import React, {useEffect, useState} from 'react';
import {Stack, TextField} from "@mui/material";
import {useDispatch} from "react-redux";
import {changeBlockValidity, changeHtmlBlock, changeVideoBlock} from "../../features/article/articleSlice";
import {useDndMonitor} from '@dnd-kit/core';
import Box from "@mui/material/Box";
import "video.js/dist/video-js.css";
import '@videojs/themes/dist/city/index.css';
import {useVideoJS} from "../../hooks/useVideoJS";

export default function VideoBlock(props) {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);
    const {options, onReady, block} = props;

    const dispatch = useDispatch();
    const { Video, player, ready } = useVideoJS(
        {
            sources: [{ src: block.src, type:block.src_type }],
            techOrder: ["youtube"],
            controls: true,
            responsive: true,
            fluid: true,
        },
        "video-js vjs-theme-city vjs-big-play-centered "
    );

    useDndMonitor({
        onDragStart(event) {

        },
        onDragEnd(event) {

        },
    });

    useEffect(() => {
        dispatch(changeBlockValidity({
            valid: (props.block.src !== ''),
            blockId: props.block.id,
            pageId: props.pageId
        }));
    }, [props.block.src, props.block.id, props.pageId])

    const updateVideoUrl = (event) => {
        // setVideoUrl(event.target.value)
        dispatch(changeVideoBlock({event:event, pageId: props.pageId, blockId: props.block.id}));
    }


    return (
        <Box>
            <Stack>
                <TextField fullWidth label="Video Url" id="videoUrl" value={block.src} onChange={updateVideoUrl} />
                <Video />
            </Stack>
        </Box>

    );
}