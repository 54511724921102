import React, { useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import AIHeadlinePicker from "./HeadlinePicker";


export default function AiToolbox(props) {
    //a component that displays icons for each ai tool and allows the user to click like a button
    const article = props.article;
    const [headlinePickerOpen, setHeadlinePickerOpen] = useState(false);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const handleHeadlineClose = () => {
        setHeadlinePickerOpen(false);
    }



    const headlineButton = () => {
        return (
            <div>
                {(!headlinePickerOpen ?<Button key="create-button" fullWidth variant="outlined" onClick={() => setHeadlinePickerOpen(true)}>Generate Headline</Button>  : '')}
                {(headlinePickerOpen ? <AIHeadlinePicker open={headlinePickerOpen} article={article} onHeadlinePicked={handleHeadlineClose} /> : '')}
            </div>
        )
    }


    return (<Accordion defaultExpanded={isDesktop} sx={{maxWidth: "100%"}}>
        <AccordionSummary expandIcon={<ExpandMore/>} sx={{transition:'0.3s ease-in-out all'}}>
            <Typography>AI Toolbox</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {headlineButton()}
        </AccordionDetails>
    </Accordion>);

}