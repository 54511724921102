import React, {useState}  from 'react';
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import {PhotoLibrary} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import MediaList from "./Blocks/ImageBlock/MediaList";
import {imageDeliveryUrl} from "../app/helpers";

export default function ImageSelector(props) {
    const [isHovering, setIsHovering] = useState(false);
    const [open, setOpen] = React.useState(false);
    const image = props.image;

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (media) => {
        props.handleSelect(media)
        handleClose();
    }


    let overlayCss = {
        display:(isHovering ? 'flex' : 'flex'),
        opacity: (isHovering ? 1 : 0),
        background:'rgba(0,0,0,.8)',
        transition:'all 0.3s ease-in-out',
        color:'#fff',
        position:'absolute',
        width:'100%',
        height:'100%',
        alignItems: 'center',
        justifyContent: 'center',
        cursor:'pointer'
    }

    let addCss = {
        display:'flex',
        background:'rgba(0,0,0,.8)',
        transition:'all 0.3s ease-in-out',
        color:'#fff',
        position:'absolute',
        width:'100%',
        height:'100%',
        alignItems: 'center',
        justifyContent: 'center',
        cursor:'pointer'
    }

    const renderImageBlock = () => {
        if(image !== null) {
            let originalRendition = image.renditions.original
            let thumbnailRendition = image.renditions.thumbnail
            return (
                <Box  sx={{position:'relative'}} >
                    <Box component="div" sx={overlayCss} onMouseOut={handleMouseOut} onMouseOver={handleMouseOver} onClick={handleClickOpen}>
                        <Stack spacing={2}  justifyContent="center"
                               alignItems="center">
                            <PhotoLibrary sx={{fontSize: 80}} />
                            <Typography>Change Image</Typography>
                        </Stack>
                    </Box>
                    <MediaList open={open} onClose={handleClose} onSelect={handleSelect} value={[image]}></MediaList>
                    <Box component="div">
                        <Box component="img"   referrerPolicy="no-referrer" src={imageDeliveryUrl(originalRendition.href)} sx={{width:'100%'}} />
                    </Box>
                </Box>)
        }
        return (
            <Box  sx={{position:'relative'}} >
                <Box component="div" sx={addCss} onClick={handleClickOpen}>
                    <Stack spacing={2}  justifyContent="center"
                           alignItems="center">
                        <PhotoLibrary sx={{fontSize: 80}} />
                        <Typography>Add Image</Typography>
                    </Stack>
                </Box>
                <MediaList open={open} onClose={handleClose} onSelect={handleSelect} value={[]}></MediaList>
                <Box component="div">
                    <Box component="div"  sx={{width:'100%', aspectRatio: '16/9'}} />
                </Box>
            </Box>
        )
    }

    return renderImageBlock()
}