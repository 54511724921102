import React, {useEffect, useState} from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, InputAdornment,
    Stack, TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import {useDescribeMediaMutation, useUpdateMediaMutation} from "../../../../features/api/apiSlice";
import {Close, Save, Visibility} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";


export default function EditDescription(props) {
    const [media, setMedia] = useState(props.media)
    const [dirty, setDirty] = useState(false);
    const [updateMedia, updateResult] = useUpdateMediaMutation()
    const [describeMedia, describeResult] = useDescribeMediaMutation()

    if(!media.description) {
        setMedia((prevState) => ({
            ...prevState,
            description: {
                caption: '',
                tags: []
            }
        }))
    }
    const saveDescription = () => {
        updateMedia(media).then(setDirty(false)).then(props.onClose());
    }

    const handleCancel = () => {
        setDirty(false);
        props.onClose();
    }

    const deleteTag = (tag) => {
        setDirty(true);
        let tags = media.description.tags.filter((oldTag) => tag !== oldTag);
        setMedia((prevState) => ({
            ...prevState,
            description: {
                ...prevState.description,
                tags: [...tags]
            }
        }))
    }

    const renderTags = () => {
        return media.description.tags.map((tag) => (<Chip sx={{margin:'2.5px'}} key={tag} label={tag} color="primary" onDelete={() => deleteTag(tag)} />))
    }

    const handleTagKeyDown = (event) => {
        if (event.key === 'Enter') {
            setDirty(true);
            let tags = [...media.description.tags, event.target.value.toLowerCase()];
            setMedia((prevState) => ({
                ...prevState,
                description: {
                    ...prevState.description,
                    tags: [...tags]
                }
            }))
            event.target.value = '';
        }
    }

    const handleCaptionChange = (event) => {
        setDirty(true);
        setMedia((prevState) => ({
            ...prevState,
            description: {
                ...prevState.description,
                caption: event.target.value
            }
        }))
    }

    const handleDescribeResult = () => {
        describeMedia(media).then(setDirty(false))
    }

    useEffect(() => {
        if(!dirty) {
            setMedia(props.media);
        }
    })

    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth="large">
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography>Edit Description</Typography>
                    <IconButton onClick={handleCancel} size="small"><Close fontSize="small" /></IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack direction={{xs: 'column', md:'row'}} spacing={2}>
                    <Box  sx={{ width: {xs: '100%', md:'350px'}}}>
                        <Box component="img" sx={{ width: {xs: '100%', md:'350px'}}} loading="lazy"
                         referrerPolicy="no-referrer" src={media.renditions.original.href} />
                    </Box>
                    <Stack spacing={2} p={2} sx={{minWidth:{xs:'100%', md:'350px'}, maxWidth:{xs:'100%', md:'350px'}}} >
                        <TextField id="outlined-basic" onChange={handleCaptionChange} fullWidth label="Caption" variant="outlined" value={media.description.caption} />
                        <Stack spacing={1}>
                            <TextField size="small" label="Add Tag" variant="outlined" onKeyDown={handleTagKeyDown}  helperText="Press enter to add tag" />
                            <Box>
                                {renderTags()}
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <LoadingButton  onClick={handleDescribeResult} variant="contained" loading={describeResult.isLoading}
                        loadingPosition="start"
                         color="secondary"
                        startIcon={<Visibility />}>AI Describe Image</LoadingButton>
                <LoadingButton  onClick={saveDescription} variant="contained" loading={updateResult.isLoading}
                        loadingPosition="start"
                                disabled={!dirty}
                         color="primary"
                        startIcon={<Save />}>Save</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}