import Paper from "@mui/material/Paper";
import React from "react";
import {useSelector} from "react-redux";
import Standard from "./ArticleFields/Standard";
import Event from "./ArticleFields/Event";


export default function ArticleFields() {
    const article = useSelector((state) => state.article)

    if(article.type === "standard") {
        return (<Standard article={article} />)
    }
    if(article.type === "event") {
        return (<Event article={article} />)
    }
    return (
        <Paper p={2}>
            Incorrect Type
        </Paper>
    )
}

