import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from "react-redux";
import {changeBlockValidity, changeImageInBlock} from "../../features/article/articleSlice";
import ImageSelector from "../ImageSelector";



function CloseIcon() {
    return null;
}

export default function ImageBlock(props) {

    const image = props.block.image;
    const dispatch = useDispatch();


    const handleSelect = (media) => {
        dispatch(changeImageInBlock({
            media: media[0],
            blockId: props.block.id,
            pageId: props.pageId
        }))
    }

    useEffect(() => {
        dispatch(changeBlockValidity({
            valid: (props.block.image !== null),
            blockId: props.block.id,
            pageId: props.pageId
        }));
    }, [props.block.image, props.block.id, props.pageId])



    return (<ImageSelector image={image} handleSelect={handleSelect} /> );
}