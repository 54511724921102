import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useGetArticleQuery, useSaveArticleMutation} from "../features/api/apiSlice";
import {useNavigate, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import {useDispatch, useSelector} from 'react-redux'
import {
    addPage,
    changeArticleField,
    clear,
    extraFields,
    initialState,
    setAuthor,
    setDirty,
    setStateFromServer
} from "../features/article/articleSlice";
import Pages from "../components/Pages";
import {
    Alert,
    AlertTitle,
    Button,
    CircularProgress,
    Fab,
    Portal,
    Snackbar,
    Stack,
    useMediaQuery,
    useTheme,
    Zoom
} from "@mui/material";
import {Backspace, Close, NoteAdd, Save} from "@mui/icons-material";
import ArticleFields from "../components/ArticleFields";
import Box from "@mui/material/Box";
import ArticleTownDetails from "../components/ArticleTownDetails";
import {ObjectID} from "bson";
import ArticleStatus from "../components/ArticleStatus";
import {usePrompt} from "../hooks/usePrompt";
import Typography from "@mui/material/Typography";
import ArticleCategory from "../components/ArticleCategory";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import AiToolbox from "../components/Ai/AiToolbox";
import ArticlePublishedOn from "../components/ArticlePublishedOn";
import AddNewBlock from "../components/AddNewBlock";

export default function ArticleSingle() {
    let {articleId, articleType, articleCategory} = useParams();
    let {
        data,
        isLoading,
        isSuccess,
    } = useGetArticleQuery(articleId, {skip: (articleId === 'new')})
    const auth = useSelector((state) => state.auth)
    const [saveStatusOpen, setSaveStatusOpen] = React.useState(false);
    const [saveStatusType, setSaveStatusType] = React.useState('success');

    const { data: newData, isSuccess: newSuccess } = useMemo(() => {
        if (articleId === "new") {
            const data = Object.assign({}, initialState, {
                id: new ObjectID().toString(),
                type: articleType,
                category: articleCategory,
                page: [
                    {
                        id: new ObjectID().toString(),
                        blocks: [],
                        page_number: 1,
                    },
                ],
                created_on: moment().format("Y-MM-DD\\THH:mm:ssZ"),
                updated_on: moment().format("Y-MM-DD\\THH:mm:ssZ"),
                published_on: null,
                ...extraFields,
            });
            return { data, isSuccess: true };
        } else {
            return { data: null, isSuccess: false };
        }
    }, [articleId, articleType, articleCategory]);

    if (newSuccess) {
        data = newData;
        isSuccess = newSuccess;
    }

    const article = useSelector((state) => state.article)


    const dispatch = useDispatch();
    const isValid = useMemo(() => {
        return article.valid.blocks && article.valid.url && article.valid.fields;
    }, [article.valid]);

    let [sidebarElem, setSidebarElem] = useState(null);
    const sidebarPortalRef = useCallback((node) => {
        setSidebarElem(node);
    });
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const [saveArticle, saveArticleData] = useSaveArticleMutation()
    const navigate = useNavigate();

    useEffect(() => {
        if(article.status === 'draft') {
            dispatch(changeArticleField({ published_on: null }));
        }
    }, [article.status, dispatch])

    useEffect(() => {
        if (isSuccess) {
            dispatch(setStateFromServer(data))
        }
    }, [isSuccess, article.initialLoad, isLoading, data])

    useEffect(() => {
        if (!article.author.id) {
            dispatch(setAuthor({
                id: auth.idTokenClaims['https://admin.eyeon.uk/author_id'],
                name: auth.idTokenClaims['name']
            }))
        }
    }, [article.author, auth])


    const [flag, setFlag, next] = usePrompt(true);
    useEffect(() => {
        if (flag) {
            dispatch(clear());
            next();
        }
    }, [flag]);

    const handleSave = () => {
        console.log(data);
        if (isValid) {
            if (articleId === "new") {
                saveArticle(article).unwrap().then(result => {
                    setFlag(false);
                    navigate('/articles/' + result.id)
                    setFlag(true);
                    dispatch(setStateFromServer(result));
                    setSaveStatusType('success')
                    setSaveStatusOpen(true);
                }).catch((error) => {
                    console.log(error);
                    setSaveStatusType('error')
                    setSaveStatusOpen(true);
                });
            } else {
                saveArticle(article).unwrap().then(result => {
                    dispatch(setStateFromServer(result));
                    setSaveStatusType('success')
                    setSaveStatusOpen(true);
                    let articlePrimaryUrl = article.url.find((articleUrl) => {
                        return articleUrl.is_primary;
                    });
                    if (articlePrimaryUrl) {
                        let purgeUrl = `https://${articlePrimaryUrl.town.domain}/purge?purge=1&url=${encodeURIComponent(`https://${articlePrimaryUrl.town.domain}/${articlePrimaryUrl.url}`)}`;
                        fetch(purgeUrl)
                            .then(response => {
                                if (!response.ok) {
                                    throw new Error('Network response was not ok');
                                }
                                return response.text();
                            })
                            .then(data => {
                                console.log('Page purged successfully');
                            })
                            .catch(error => {
                                console.error('There has been a problem with your fetch operation:', error);
                            });
                    }
                }).catch((error) => {
                    console.log(error);
                    setSaveStatusType('error')
                    setSaveStatusOpen(true);
                });
            }

        }
    }

    const handleSaveNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaveStatusOpen(false);
    };

    const handleAddPage = useCallback(() => {
        dispatch(addPage());
    }, [dispatch]);


    const renderEditor = () => {

        let innerContent = (
            <Stack spacing={2} pb={8}>
                <ArticleFields/>
                {(!isDesktop ? (<Box ref={sidebarPortalRef}></Box>) : '')}
                <Tooltip title="Add Update">
                <Box onClick={() => handleAddPage()} sx={{
                    display:'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: 'height 0.3s ease-in-out',
                    // height: (!isHovering ? defaultHeight : `calc(${defaultHeight} + 20px)`),
                    cursor:'pointer',
                }}>
                        <NoteAdd  />
                </Box>
                </Tooltip>
                <Pages></Pages>
            </Stack>
        )
        let content = innerContent
        if (isDesktop) {
            content = (
                <Grid container direction="row-reverse" spacing={2} sx={{maxWidth: "100%"}}>
                    {(isDesktop ? (
                        <Grid ref={sidebarPortalRef} item xs={12} md={4} sx={{maxWidth: "100%"}}></Grid>) : '')}
                    <Grid item xs={12} md={8} sx={{maxWidth: "100%"}}>
                        {innerContent}
                    </Grid>
                </Grid>
            )
        }
        return content;
    }

    const renderSaveButton = () => {
        if (saveArticleData.isLoading) {
            return (
                <Fab variant="extended" color="primary" disabled={!isValid} onClick={handleSave}>
                    <CircularProgress sx={{mr: 1, p: 1, color: '#fff'}}/>
                    Saving
                </Fab>
            )
        }
        return (
            <Fab variant="extended" color="primary" disabled={!isValid} onClick={handleSave}>
                <Save sx={{mr: 1}}/>
                Save
            </Fab>
        )
    }


    const openArticleInNewWindow = () => {
        let articlePrimaryUrl = article.url.find((articleUrl) => {
            return articleUrl.is_primary;
        });
        if (articlePrimaryUrl) {
            window.open(`https://${articlePrimaryUrl.town.domain}/${articlePrimaryUrl.url}`);
        }
    }

    const action = () => {
        let actionButton = null;
        if (saveStatusType === "success") {
            actionButton = (<Button color="secondary" size="small" onClick={openArticleInNewWindow}>
                View on website
            </Button>);
        }

        return (<React.Fragment>
            {actionButton}
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSaveNotificationClose}
            >
                <Close fontSize="small"/>
            </IconButton>
        </React.Fragment>)
    };

    const renderSaveStatus = () => {
        let alert = (
            <Alert severity="error" onClose={handleSaveNotificationClose}>
                <AlertTitle>Error</AlertTitle>
                Something went wrong. Ask Ryan
            </Alert>)
        if (saveStatusType === "success") {
            alert = (
                <Alert severity="success" action={action()}>
                    <AlertTitle>Success</AlertTitle>
                    Article successfully saved
                </Alert>)
        }
        return (<Snackbar
            open={saveStatusOpen}
            onClose={handleSaveNotificationClose}
            autoHideDuration={6000}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        >{alert}</Snackbar>)
    }

    if (isLoading || (article.id !== articleId && articleId !== "new")) {
        return (<Box class='article-loader'>
            <Typography class='article-loader__heading'>Loading Article</Typography>
            <CircularProgress class='article-loader__progress'/>
            {renderSaveStatus()}
        </Box>)
    }

    const renderPublishedOn = () => {
        if (article.status === 'published' || article.status === 'scheduled') {
            return (<ArticlePublishedOn  article={article} publishedOn={article.published_on}></ArticlePublishedOn>)
        }
        return null;
    }

    return (
        <Box>
            <Portal container={sidebarElem}>
                <Stack spacing={2}>
                    <ArticleStatus status={article.status}></ArticleStatus>
                    {renderPublishedOn()}
                    <ArticleCategory category={article.category}></ArticleCategory>
                    <ArticleTownDetails article={article}/>
                    <AiToolbox article={article}/>
                </Stack>
            </Portal>
            {renderEditor()}
            <Box sx={{position: 'fixed', right: '20px', bottom: '20px', zIndex: '99'}}>
                <Stack direction="row" spacing={2}>
                    <Zoom
                        key="revert"
                        in={article.dirty}
                        timeout={300}
                    >
                        <Fab variant="extended" onClick={() => dispatch(setDirty(false))} title="Clear Changes">
                            <Backspace sx={{mr: 1}}/>
                            Reset
                        </Fab>
                    </Zoom>
                    <Zoom
                        key="save"
                        in={article.dirty}
                        style={{transitionDelay: '300ms'}}
                        timeout={300}
                    >
                        {renderSaveButton()}
                    </Zoom>
                </Stack>
            </Box>
            {renderSaveStatus()}
        </Box>
    );
}