import React from 'react';
import './App.css';
import {
    BrowserRouter, Route, Routes

} from "react-router-dom";


import ArticleList from "./pages/ArticleList";
import ArticleSingle from "./pages/ArticleSingle";
import Dashboard from "./pages/Dashboard";
import { useAuth0 } from "@auth0/auth0-react";
import {CircularProgress, Snackbar} from "@mui/material";
import {ProtectedRoute} from "./components/ProtectedRoute";
import {CallbackPage} from "./pages/CallbackPage";
import {Alert} from "@mui/lab";
import {Auth0ProviderWithConfig} from "./auth0-provider-with-config";
import {LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import 'moment/locale/en-gb';

function App() {

const {isLoading, error} = useAuth0();
    moment.locale('en-gb');
    if (!isLoading) {
        return (
            <div className="page-layout">
                <CircularProgress />
                <Snackbar open={error} autoHideDuration={6000}>
                    <Alert severity="error">
                        {JSON.stringify(error)}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
      return (
          <BrowserRouter>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='en-gb'>
              <Auth0ProviderWithConfig>
                  <Routes>
                      <Route path="/" element={<ProtectedRoute component={Dashboard} />}>
                          <Route path="articles" element={<ArticleList />}></Route>
                          <Route path="articles/:articleId/:articleType/:articleCategory" element={<ArticleSingle />} />
                          <Route path="articles/:articleId" element={<ArticleSingle />} />
                          <Route path="/callback" element={<CallbackPage />} />
                      </Route>
                  </Routes>
              </Auth0ProviderWithConfig>
              </LocalizationProvider>
          </BrowserRouter>

  );
}

export default App;
