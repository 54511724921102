import HtmlBlock from "../../types/Blocks/HtmlBlock";
import ImageBlock from "../../types/Blocks/ImageBlock";
import VideoBlock from "../../types/Blocks/VideoBlock";
import EmbedBlock from "../../types/Blocks/EmbedBlock";


export let HtmlBlockObject: HtmlBlock = {
    classType: "HtmlBlock",
    html: "",
    id: '',
    type: "HtmlBlock",
    valid: false
}
export let EmbedBlockObject: EmbedBlock = {
    classType: "EmbedBlock",
    html: "",
    id: '',
    type: "EmbedBlock",
    valid: false
}


export let ImageBlockObject: ImageBlock = {
    alt: "",
    classType: "ImageBlock",
    id: '',
    image: null,
    type: "ImageBlock",
    valid:false
}

export let VideoBlockObject: VideoBlock = {
    classType: "VideoBlock",
    id: '',
    src: '',
    src_type: 'video/youtube',
    type: "VideoBlock",
    valid:false
}

