import {ListItem} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Edit, MultipleStop, Star} from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import EditUrl from "../EditUrl";
import {useDispatch} from "react-redux";
import {editUrl} from "../../features/article/articleSlice";

export default function UrlListItem(props) {
    const urlItem = props.url;
    const [editOpen, setEditOpen] = useState(false);
    const dispatch = useDispatch();

    const handleClose = () => {
        setEditOpen(false);
    }

    const handleSave = (newUrl) => {
        dispatch(editUrl(newUrl));
        handleClose();
    }
    return (<ListItem
        key={urlItem.id}
        secondaryAction={
            <IconButton edge="end" aria-label="edit" onClick={() => setEditOpen(true)}>
                <Edit/>
            </IconButton>
        }
    >
        { (urlItem.is_primary ? <ListItemIcon><span title="Primary Url"><Star /></span></ListItemIcon> : '') }
        { (urlItem.redirect_to ? <ListItemIcon><span title={"Redirects to: " + urlItem.redirect_to}><MultipleStop  /></span></ListItemIcon> : '') }
        <ListItemText
            primary={<Typography title={urlItem.url} sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
            }}>{urlItem.url}</Typography>}
            secondary={urlItem.town.name + " - " + urlItem.town.region.name}
        />
        {(editOpen ? <EditUrl url={urlItem} open={editOpen} onClose={handleClose} onSave={handleSave} /> : '')}
    </ListItem>)
}