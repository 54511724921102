import {createSlice, current, PayloadAction} from '@reduxjs/toolkit'
import Article from "../../types/Article";
import {arrayMove} from "@dnd-kit/sortable";
import SortablePayload from "../../types/SortablePayload";
import HtmlBlock from "../../types/Blocks/HtmlBlock";
import ImageBlock from "../../types/Blocks/ImageBlock";
import {EmbedBlockObject, HtmlBlockObject, ImageBlockObject, VideoBlockObject} from './blocks'
import {ObjectId} from "bson";
import moment from "moment";
import EmbedBlock from "../../types/Blocks/EmbedBlock";
import Page from "../../types/Page";


export const extraFields = {
    dirty: false,
    initialLoad:false,
    valid: {
        blocks: false,
        url: false,
        fields: false
    }
}

export const initialState: Article = {
    created_on: moment().format('Y-MM-DD\\THH:mm:ssZ'),
    featured_media: null,
    headline: "",
    author: {
        id: null
    },
    id: (new ObjectId()).toString(),
    is_update: false,
    page: [] as Page[],
    status: "draft",
    tags: [],
    type: "standard",
    category: "news",
    updated_on: moment().format('Y-MM-DD\\THH:mm:ssZ'),
    published_on: null,
    url: [],
    version: 1,
    ...extraFields
}



// @ts-ignore
export const articleSlice = createSlice({
    name: 'article',
    initialState,
    reducers: {
        // @ts-ignore
        clear: (state:Article, action: PayloadAction<object>) => {
            return {
                ...initialState,
                created_on:moment().format('Y-MM-DD\\THH:mm:ssZ'),
                updated_on:moment().format('Y-MM-DD\\THH:mm:ssZ')
            };
        },
        // @ts-ignore
        setStateFromServer: (state:Article, action: PayloadAction<object>) => {
            // @ts-ignore
            return Object.assign({dirty:false, author: {id:null}},action.payload, extraFields, {initialLoad: true})
        },
        changeHtmlBlock: (state: Article, action: PayloadAction<any>) => {
            const page = state.page.find((page) => {
                return page.id === action.payload.pageId
            })
            if (page) {
                let blocks = page.blocks;
                const blockElement = blocks.find((block) => {
                    return block.id === action.payload.blockId
                })
                if (blockElement) {
                    // @ts-ignore
                    let pageIndex = state.page.indexOf(page);
                    let blocksIndex = state.page[pageIndex].blocks.indexOf(blockElement);

                    // @ts-ignore
                    if(state.page[pageIndex].blocks[blocksIndex].html.trim() !== action.payload.event.trim()) {
                        // @ts-ignore
                        state.page[pageIndex].blocks[blocksIndex].html = action.payload.event
                    }
                }
            }

        },
        changeVideoBlock: (state: Article, action: PayloadAction<any>) => {
            const page = state.page.find((page) => {
                return page.id === action.payload.pageId
            })
            if (page) {
                let blocks = page.blocks;
                const blockElement = blocks.find((block) => {
                    return block.id === action.payload.blockId
                })
                if (blockElement) {
                    // @ts-ignore
                    let pageIndex = state.page.indexOf(page);
                    let blocksIndex = state.page[pageIndex].blocks.indexOf(blockElement);
                        // @ts-ignore
                    state.page[pageIndex].blocks[blocksIndex].src = action.payload.event.target.value
                }
            }

        },
        changeEmbedBlock: (state: Article, action: PayloadAction<any>) => {
            const page = state.page.find((page) => {
                return page.id === action.payload.pageId
            })
            if (page) {
                let blocks = page.blocks;
                const blockElement = blocks.find((block) => {
                    return block.id === action.payload.blockId
                })
                if (blockElement) {
                    // @ts-ignore
                    let pageIndex = state.page.indexOf(page);
                    let blocksIndex = state.page[pageIndex].blocks.indexOf(blockElement);

                    // @ts-ignore
                    if(state.page[pageIndex].blocks[blocksIndex].html.trim() !== action.payload.event.trim()) {
                        // @ts-ignore
                        state.page[pageIndex].blocks[blocksIndex].html = action.payload.event
                    }
                }
            }

        },
        setIsValid: (state: Article, action: PayloadAction<any>) => {
            state.valid = Object.assign(state.valid, action.payload);
        },
        setDirty: (state: Article, action: PayloadAction<any>) => {
            state.dirty = action.payload;
        },
        setAuthor: (state: Article, action: PayloadAction<any>) => {
            state.author = action.payload
        },
        setPageUpdateDate: (state: Article, action: PayloadAction<{ pageId: string, update_date: string }>) => {
            const { pageId, update_date } = action.payload;
            const page = state.page.find((page) => page.id === pageId);
            if (page) {
                state.dirty = true;
                page.update_date = update_date;
            }
        },
        deletePage: (state: Article, action: PayloadAction<string>) => {
            const pageId = action.payload;
            const pageIndex = state.page.findIndex((page) => page.id === pageId);
            if (pageIndex !== -1) {
                state.dirty = true;
                state.page.splice(pageIndex, 1);
            }
        },
        addPage: (state: Article) => {
            state.dirty = true;
            const newPage = {
                update_date: moment().format('Y-MM-DD\\THH:mm:ssZ'),
                id: (new ObjectId()).toString(),
                page_number: state.page.length + 1,
                blocks: []
            } as Page;
            // @ts-ignore
            state.page.push(newPage);
            addBlockToPage({type: "HtmlBlock", pageId: newPage.id, currentIndex: -1});
        },
        reorderBlocks: (state: Article, action: PayloadAction<SortablePayload & { sourcePageId: string, targetPageId: string }>) => {
            state.dirty = true;
            let active = action.payload.active;
            let over = action.payload.over;
            let sourcePageId = action.payload.sourcePageId;
            let targetPageId = action.payload.targetPageId;

            let sourcePage, targetPage, oldElement, newElement, oldIndex, newIndex;

            for (let i = 0; i < state.page.length; i++) {
                let blocks = state.page[i].blocks;
                if (state.page[i].id === sourcePageId) {
                    oldElement = blocks.find((block) => block.id === active);
                    if (oldElement) {
                        sourcePage = state.page[i];
                        oldIndex = blocks.indexOf(oldElement);
                    }
                }
                if (state.page[i].id === targetPageId) {
                    newElement = blocks.find((block) => block.id === over);
                    if (newElement) {
                        targetPage = state.page[i];
                        newIndex = blocks.indexOf(newElement);
                    }
                }
            }

            if (sourcePage && targetPage && oldElement && newElement && oldIndex !== undefined && newIndex !== undefined) {
                sourcePage.blocks.splice(oldIndex, 1);
                targetPage.blocks.splice(newIndex, 0, oldElement);
            }
        },
        addBlockToPage: (state: Article, action: PayloadAction<any>) => {
            state.dirty = true;
            let payload = action.payload;
            // @ts-ignore
            let blockToAdd;
            if (payload.type === "HtmlBlock") {
                blockToAdd = HtmlBlockObject
            }
            if (payload.type === "ImageBlock") {
                blockToAdd = ImageBlockObject
            }
            if (payload.type === "VideoBlock") {
                blockToAdd = VideoBlockObject
            }
            if (payload.type === "EmbedBlock") {
                blockToAdd = EmbedBlockObject
            }

            // @ts-ignore
            blockToAdd = Object.assign({}, blockToAdd, {id: (new ObjectId()).toString()});
            const page = state.page.find((page) => {
                return page.id === payload.pageId
            })
            if (page) {
                // let currentPage = current(page);
                // @ts-ignore
                page.blocks.splice(payload.currentIndex + 1, 0, blockToAdd);
                // console.log(currentPage.blocks)
                // // @ts-ignore
                // state.page[state.page.indexOf(page)].blocks = currentPage.blocks;
            }
        },
        removeBlockFromPage: (state: Article, action: PayloadAction<any>) => {
            state.dirty = true;
            let payload = action.payload;
            const page = state.page.find((page) => {
                return page.id === payload.pageId
            })
            if (page) {
                page.blocks.splice(payload.blockIndex, 1);
            }
        },
        changeImageInBlock: (state: Article, action: PayloadAction<any>) => {
            state.dirty = true;
            let payload = action.payload;
            let media = payload.media;
            let blockId = payload.blockId;
            let pageId = payload.pageId;

            const page = state.page.find((page) => page.id === pageId);
            if (page) {
                const block = page.blocks.find((block) => block.id === blockId);
                if (block) {
                    // @ts-ignore
                    block.image = media;
                    // @ts-ignore
                    if (block.image.description) {
                        // @ts-ignore
                        block.alt = block.image.caption;
                    }
                } else {
                    console.error(`Block with id ${blockId} does not exist on page with id ${pageId}`);
                }
            } else {
                console.error(`Page with id ${pageId} does not exist`);
            }
        },
        changeBlockValidity: (state: Article, action: PayloadAction<any>) => {
            let payload = action.payload;
            let valid = payload.valid;
            let blockId = payload.blockId;
            let pageId = payload.pageId;

            const page = state.page.find((page) => page.id === pageId);
            if (page) {
                const block = page.blocks.find((block) => block.id === blockId);
                if (block) {
                    block.valid = valid;
                } else {
                    console.error(`Block with id ${blockId} does not exist on page with id ${pageId}`);
                }
            } else {
                console.error(`Page with id ${pageId} does not exist`);
            }
        },
        changeArticleField:(state: Article, action: PayloadAction<any>) => {
            state.dirty = true;
            state = Object.assign(state, action.payload);
        },
        editUrl:(state: Article, action: PayloadAction<any>) => {
            state.dirty = true;
            let newUrl = action.payload;
            let oldUrl = state.url.find((url) => {
                return newUrl.id === url.id
            })
            // @ts-ignore
            let oldIndex = state.url.indexOf(oldUrl)
            let primaryUrl = state.url.find((url) => {
                return url.is_primary
            })
            /**
             * delete redirect details if not redirect
             */
            if(newUrl.redirect_to === "") {
                delete newUrl.redirect_to;
            }
            if(newUrl.redirect_type === "") {
                delete newUrl.redirect_type;
            }

            /**
             * remove old primary url if there is one
             */
            if(oldUrl) {
                if(oldUrl.is_primary !== newUrl.is_primary) {
                    if(primaryUrl && primaryUrl.id !== newUrl.id) {
                        // @ts-ignore
                        let primaryIndex = state.url.indexOf(primaryUrl);
                        state.url[primaryIndex].is_primary = false;
                    }
                }
                state.url[oldIndex] = newUrl;
            } else {
                let similarUrls = state.url.filter((url) => {
                    if(newUrl.url === url.url) {
                        if(newUrl.town.name === url.town.name) {
                            return true;
                        }
                    }
                    return false;
                });
                if(similarUrls.length === 0) {
                    // @ts-ignore
                    state.url.push(newUrl)
                }

            }
        },
    }
})

export const {
    setStateFromServer,
    reorderBlocks,
    changeHtmlBlock,
    addBlockToPage,
    removeBlockFromPage,
    changeImageInBlock,
    setDirty,
    changeBlockValidity,
    editUrl,
    changeArticleField,
    setIsValid,
    clear,
    setAuthor,
    changeVideoBlock,
    changeEmbedBlock,
    setPageUpdateDate,
    deletePage,
    addPage

} = articleSlice.actions

export default articleSlice.reducer