import React, {useEffect, useState} from 'react';
import {useGetRegionsQuery, useGetRegionsTownsQuery} from "../../features/api/apiSlice";
import {Autocomplete, Stack, TextField} from "@mui/material";
import {useLocalStorage} from "../../app/hooks";
import {Skeleton} from "@mui/lab";


export default function TownSelector(props) {
    const [regionInputValue, setRegionInputValue] = useState('');
    const [townInputValue, setTownInputValue] = useState('');
    const [currentRegion, setCurrentRegion] = useLocalStorage("region", (props.value ? props.value.region : {name:''}));
    const [currentTown, setCurrentTown] = useLocalStorage("town",(props.value && Object.keys(props.value).length ? props.value : {name:''}))
    const regions = useGetRegionsQuery('');
    const towns = useGetRegionsTownsQuery((currentRegion ? currentRegion.id : ''))

    const handleRegionSelect = (event, newValue) => {
        if(newValue === null) {
            newValue = {name:'', id:''}
        }
        setCurrentRegion(newValue);
    }
    const handleTownSelect = (event, newValue) => {
        if(newValue)  {
            props.onSelect(newValue);
            setCurrentTown(newValue);
        }
    }

    useEffect(() => {
        if(props.value && Object.keys(props.value).length) {
            if(!props.value || !currentRegion  || currentRegion.id !== props.value.region.id) {
                setCurrentTown({name:''})
            } else {
                setCurrentTown(props.value)
            }
        } else if (!currentTown && !currentRegion) {
            setCurrentTown({name:''})
        }
    }, [currentRegion, props.value])

    useEffect(() => {
        if(props.value && !Object.keys(props.value).length) {
            props.onSelect(currentTown);
        }
    }, [currentTown]);
    const renderRegionsInput = () => {
        if(regions.isSuccess) {
            return  (
                <Autocomplete
                    value={currentRegion}
                    key="region-selector"
                    onChange={handleRegionSelect}
                    inputValue={regionInputValue}
                    onInputChange={(event, newInputValue) => {
                        setRegionInputValue(newInputValue);
                    }}
                    margin="dense"
                    fullWidth
                    options={[{name:''}, ...regions.data]}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option,value) => {
                        return option.name === value.name
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Region" />}
                />
            )
        }
        return (<Skeleton variant='rectangular'>
            <Autocomplete
                fullWidth
                options={[{name:''}, []]}

                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Region" />}
            />
        </Skeleton>)
    }

    const renderTownsInput = () => {
        if(towns.isSuccess) {
            return  (
                <Autocomplete
                    key="town-selector"
                    value={currentTown}
                    onChange={handleTownSelect}
                    inputValue={townInputValue}
                    onInputChange={(event, newInputValue) => {
                        setTownInputValue(newInputValue);
                    }}
                    margin="dense"
                    fullWidth
                    options={[{name:''}, ...towns.data]}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option,value) => {
                        return option.name === value.name
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={(currentRegion.name === 'Global' ? 'Category' : 'Town')} />}
                />
            )
        }
        return (<Skeleton variant='rectangular'>
            <Autocomplete
                fullWidth
                options={[{name:''}, []]}

                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Region" />}
            />
        </Skeleton>)
    }

    return (
        <Stack spacing={3} mt={2} direction={(props.direction ? props.direction : 'column')}>
            {renderRegionsInput()}
            {renderTownsInput()}
        </Stack>
    );
}