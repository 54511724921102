import React, { useState, useEffect } from "react";
import { changeArticleField, setIsValid } from "../features/article/articleSlice";
import { useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";

export default function ArticlePublishedOn(props) {
    const dateTime = props.publishedOn;
    const article = props.article;
    const [initialDateTime] = useState(dateTime);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        let time = event;
        dispatch(changeArticleField({ published_on: time.format() }));
    };

    useEffect(() => {
        if(dateTime === null) {
            handleChange(moment())
        }
    })

    const renderIfValue = () => {
        const value = dateTime !== null ? moment(dateTime) : moment();
        return (
            <MobileDateTimePicker
                label="Article Published Date"
                value={value}
                onChange={handleChange}
                defaultValue={moment()}
                sx={{ width: "100%" }}
            />
        );
    };

    return <Paper>{renderIfValue()}</Paper>;
}
