import React, {useEffect, useState} from 'react'
import urlSlug from 'url-slug'
import {
    Button, Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl,
    FormControlLabel,
    FormGroup, InputLabel, MenuItem, Select, Stack, Switch, TextField
} from "@mui/material";
import TownSelector from "./ArticleTownDetails/TownSelector";
import {ObjectID} from "bson";

export default function EditUrl(props) {
    let newURL = (props.new === true ? props.new : false)
    let slug = ''
    if(newURL) {
        slug = urlSlug(props.article.headline);
    }
    const [urlState, setUrlState] = useState((props.url ? props.url : {
        id: (new ObjectID().toString()),
        is_primary: false,
        town: {},
        type: "article",
        redirect_to: "",
        redirect_type: "",
        url: slug
    }));
    let isRedirectInitial = false;
    if(urlState.redirect_to) {
        isRedirectInitial = true;
    }
    const [isRedirectSwitch, setIsRedirectSwitch] = useState(isRedirectInitial);
    const [isValid, setIsValid] = useState(false);
    const handleTownSelect = (newValue) => {
        setUrlState((prevState) => ({
            ...prevState,
            town: newValue
        }))
    }
    const handlePrimaryChange = (event, newValue) => {
        setUrlState((prevState) => ({
            ...prevState,
            is_primary: newValue
        }))
    }
    const handleRedirectTypeChange  = (event, newValue) => {
        console.log(newValue)
        setUrlState((prevState) => ({
            ...prevState,
            redirect_type: newValue.props.value
        }))
    }

    const handleRedirectToChange  = (event) => {
        setUrlState((prevState) => ({
            ...prevState,
            redirect_to: event.target.value
        }))
    }

    const handleUrlChange  = (event) => {
        setUrlState((prevState) => ({
            ...prevState,
            url: event.target.value
        }))
    }

    useEffect(() => {
        if(isRedirectSwitch !== true) {
            setUrlState((prevState) => ({
                ...prevState,
                redirect_type: '',
                redirect_to: ''
            }))
        }
    }, [isRedirectSwitch])

    useEffect(() => {
        let valid = true;
        if(Object.keys(urlState.town).length === 0) {
            valid = false;
        }
        if(urlState.url === "") {
            valid = false;
        }
        if(isRedirectSwitch) {
            if(!urlState.redirect_to) {
                valid = false;
            }
            if(!urlState.redirect_type) {
                valid = false;
            }
        }
        setIsValid(valid);
    }, [urlState, isRedirectSwitch]);


    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="edit-dialog-title"
            aria-describedby="edit-dialog-description"
        >
            <DialogTitle id="edit-dialog-title">
                {"Edit Url"}
            </DialogTitle>
            <DialogContent py={2}>
                <Stack spacing={3}>
                    <TownSelector value={urlState.town ?? {}} onSelect={handleTownSelect}></TownSelector>
                    <TextField disabled={!newURL} label="URL" value={urlState.url} onChange={handleUrlChange}></TextField>
                    <FormControlLabel control={<Switch checked={urlState.is_primary}
                                                       onChange={handlePrimaryChange} />} label="Primary URL" />
                    <FormControlLabel control={<Switch checked={isRedirectSwitch}
                                                       onChange={(event, newValue) => setIsRedirectSwitch(newValue)} />} label="Is Redirect" />
                    <Collapse in={isRedirectSwitch}>
                        <Stack spacing={2}>
                            <FormControl fullWidth>
                                <InputLabel id="redirect-type-label">Redirect Type</InputLabel>
                                <Select
                                    labelId="redirect-type-label"
                                    value={urlState.redirect_type}
                                    label="Redirect Type"
                                    onChange={handleRedirectTypeChange}
                                >
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value={301}>Permanent (301)</MenuItem>
                                    <MenuItem value={302}>Temporary (302)</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField label="Redirect Url" value={urlState.redirect_to} onChange={handleRedirectToChange}></TextField>
                        </Stack>
                    </Collapse>

                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button variant="contained" disabled={!isValid} color="primary" onClick={() => props.onSave(urlState)} autoFocus>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}