import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {AddCircle} from "@mui/icons-material";
import {Button, Dialog, DialogActions, DialogContent, Modal, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AddNewBlockButton from "./AddNewBlockButton";


export default function AddNewBlock(props) {
    const [isHovering, setIsHovering] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setCurrentIndex(null);
        if(typeof props.onBlockAdded === 'function') {
            props.onBlockAdded();
        }
    }

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const handleClick = (blockIndex) => {
        setCurrentIndex(blockIndex);
        handleOpen();
    }

    useEffect(() => {
        if(typeof props.open === 'boolean') {
            setOpen(props.open);
        }
    }, [props.open])

    let flex = { display:'flex',
        alignItems: 'center',
        justifyContent: 'center'}

    let insideContent = (
        <AddCircle sx={{ opacity:0.3, height:(props.buttonHeight ? props.buttonHeight : '20px') }} />
    )
    if(isHovering) {
        insideContent = (<Stack sx={{
            opacity: (isHovering ? '1' : '0'),
            transition: 'opacity 0.3s ease-in-out',
            display:'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }} >

            <AddCircle sx={{ opacity:0.3, height:(props.buttonHeight ? props.buttonHeight : '20px') }}></AddCircle>
        </Stack>)
    }

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const defaultHeight = (props.buttonHeight ? props.buttonHeight : '30px');

    return (
        <div>
            <Box onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={() => handleClick(props.blockIndex)} sx={{
                display:'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'height 0.3s ease-in-out',
                height: (!isHovering ? defaultHeight : `calc(${defaultHeight} + 20px)`),
                cursor:'pointer',
            }}>
                <Box component="div" sx={{width:'100%',height:"100%",...flex}}>
                    {insideContent}
                </Box>
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                outline={0}

            >
                <DialogContent>
                    <Grid container direction={{xs: 'column', md:'row'}}   sx={{width:{xs: '320px', md:'500px'}}} spacing={2}>
                        <AddNewBlockButton type="HtmlBlock" currentIndex={currentIndex} pageId={props.pageId} handleClose={handleClose}/>
                        <AddNewBlockButton type="ImageBlock" currentIndex={currentIndex} pageId={props.pageId}  handleClose={handleClose} />
                        <AddNewBlockButton type="VideoBlock" currentIndex={currentIndex} pageId={props.pageId}  handleClose={handleClose} />
                        <AddNewBlockButton type="EmbedBlock" currentIndex={currentIndex} pageId={props.pageId}  handleClose={handleClose} />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined"  onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}