import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Dashboard from "./Dashboard";
import Box from "@mui/material/Box";


export const CallbackPage = () => {
    const { error } = useAuth0();

    if (error) {
        return (
            <Box>
                <div className="content-layout">
                    <h1 id="page-title" className="content__title">
                        Error
                    </h1>
                    <div className="content__body">
                        <p id="page-description">
                            <span>{error.message}</span>
                        </p>
                    </div>
                </div>
            </Box>
        );
    }

    return (
        <Dashboard />
    );
};