import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    accessToken: null,
    idTokenClaims: {},
    isAuthenticated: false
}


export const authSlice = createSlice({
    name: 'article',
    initialState,
    reducers: {
        setAccessToken: (state, action) => {
            state.accessToken = action.payload
            state.isAuthenticated = true;
        },
        setIdTokenClaims:(state, action) => {
            state.idTokenClaims = action.payload;
        }
    }
})

export const {
    setAccessToken,
    setIdTokenClaims
} = authSlice.actions

export default authSlice.reducer