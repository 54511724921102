import {
    Accordion, AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Button, Collapse,
    ListItem,
    Stack,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {Add, Edit, ExpandMore, MultipleStop, Star} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import UrlListItem from "./ArticleTownDetails/UrlListItem";
import EditUrl from "./EditUrl";
import {useDispatch} from "react-redux";
import {editUrl, setIsValid} from "../features/article/articleSlice";
import {TransitionGroup} from "react-transition-group";


export default function ArticleTownDetails(props) {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const article = props.article;
    const [primaryUrl, setPrimaryUrl] = useState({
        url: ''
    })
    const [otherUrls, setOtherUrls] = useState(article.url.filter((url) => {
        return !url.is_primary
    }));
    const [newOpen, setNewOpen] = useState(false);
    const dispatch = useDispatch();

    const handleNewClose = () => {
        setNewOpen(false);
    }

    const handleNewSave = (newUrl) => {
        dispatch(editUrl(newUrl));
        handleNewClose();
    }


    useEffect(() => {
        let foundUrl = article.url.find((url) => {
            return url.is_primary;
        });
        if (foundUrl) {
            setPrimaryUrl(foundUrl);
        } else {
            setPrimaryUrl({
                url: '',
                id: ''
            });
        }
        setOtherUrls(article.url.filter((url) => {
            return !url.is_primary
        }))
    }, [props.article.url])

    useEffect(() => {
        if(primaryUrl.url) {
            dispatch(setIsValid({url: true}));
        } else {
            dispatch(setIsValid({url: false}));
        }
    }, [primaryUrl,article])

    const primaryUrlDisplay = () => {
        let content = (
            <ListItem key="create-url" sx={{background: theme.palette.error.main, color: theme.palette.error.contrastText}}>
                <ListItemText primary="No Primary URL Set" />
            </ListItem>
        )
        if (primaryUrl.town) {
            content = (<UrlListItem key={primaryUrl.id} url={primaryUrl} />)
        }
        return content;
    }

    const createUrlButton = () => {
        return (
            <div>
                <Button key="create-button" fullWidth variant="outlined" onClick={() => setNewOpen(true)}>Create Url</Button>
                {(newOpen ? <EditUrl new={true} open={newOpen} article={article} onClose={handleNewClose} onSave={handleNewSave} /> : '')}
            </div>
        )
    }
    return (
        <Accordion defaultExpanded={isDesktop} sx={{maxWidth: "100%"}}>
            <AccordionSummary expandIcon={<ExpandMore/>} sx={{transition:'0.3s ease-in-out all', background: (article.valid.url ? 'inherit': theme.palette.error.main), color: (article.valid.url ? 'inherit': theme.palette.error.contrastText)}}>
                <Typography>Url</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List pt={0}>
                    <TransitionGroup>
                        <Collapse key="primary-url-trans">{primaryUrlDisplay()}</Collapse>
                        {otherUrls.map(url => (<Collapse key={url.id}><UrlListItem key={url.id} url={url} /></Collapse>))}
                    </TransitionGroup>
                </List>
            </AccordionDetails>
            <AccordionActions>
                {createUrlButton()}
            </AccordionActions>
        </Accordion>
    )
}